import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { PrivateRoute } from 'routes'

export const reportsRoutes: RouteObject = {
  path: 'reports',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: (
        <PrivateRoute permission='can_view_price_list'>
          <Navigate replace to='rank' />
        </PrivateRoute>
      ),
    },
    {
      path: 'rank',
      async lazy() {
        const { Rank, loader } = await import('./rank')
        return {
          element: (
            <PrivateRoute permission='can_view_price_list'>
              <Rank />
            </PrivateRoute>
          ),
          loader,
        }
      },
    },
  ],
}
